export const numberWithCommas = function (x, fixed = null) {
  let num = x;
  if (typeof num === 'string') {
    num = parseFloat(num);
  }
  if (num === 0) return '0';
  if (!num) return '-';

  if (fixed === null) {
    num = parseFloat(num.toFixed(4));
    if (Math.round(num) === num) {
      num = num.toFixed(0);
    }
  }
  const parts = num.toString().split('.');
  let res = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  if (parts[1]) {
    res += `.${parts[1]}`;
  }
  return res;
}

export const simpleNumber = function (x, fixed = null, maxFixed = 4) {
  let num = x;
  if (typeof num === 'string') {
    num = parseFloat(num);
  }
  if (num === 0) return '0';
  if (!num) return '-';

  if (fixed === null) {
    num = parseFloat(num.toFixed(maxFixed));
    if (Math.round(num) === num) {
      num = num.toFixed(0);
    }
  }
  return num.toString();
}
